<!--
 * @Descripttion : 报检单详情--目前用APP原生&小程序（H5暂未用
-->
<template>
  <div class="ml_quotationprice">
    <div class="ml_quotationprice_head">
      <div class="ml_head"></div>
      <div class="ml_car_box">
        <div class="ml_userinfo">
          <div>
            <span class="ml_user_name">致{{pageData.customerName}}</span>
            <span class="ml_user_title">(先生/女士)</span>
          </div>
          <span class="ml_user_phone">{{pageData.customerPhone}}</span>
        </div>
        <div class="ml_carinfo">
          <img class="ml_car_img" :src="pageData.firstImage" alt="">
          <span class="ml_car_name">{{pageData.vehicleName}}</span>
        </div>
        <div class="ml_price_box">
          <span>{{pageData.vehiclePrice}}</span>
          <span>元</span>
          <span>新车价{{pageData.newCarGuidePrice}}元</span>
        </div>
        <div class="ml_price_service">综合服务费：{{pageData.comprehensiveServiceFee}}元</div>
      </div>
      <div class="ml_price_purchase">
        <div class="ml_price_purchase_item">
          <span>金融购车价</span>
          <span>{{pageData.loanPurchasePrice}}元</span>
        </div>
        <div class="ml_price_purchase_item">
          <span>VIN码</span>
          <span>{{pageData.vin}}</span>
        </div>
      </div>  
    </div>                   
    <div class="ml_content" style="background-image: linear-gradient(157deg,rgba(198, 217, 255,0.7) 0%,#ffffff 40px);">
      <div class="ml_content_title">购车方式</div>
      <div class="ml_content_list">
        <div class="ml_content_list_title">一次性付款</div>
        <div class="ml_content_list_item">
          <span>全款:</span>
          <span>{{pageData.vehiclePrice}}元</span>
        </div>
      </div>
      <div class="ml_content_list">
        <div class="ml_content_list_title">按揭付款</div>
        <div class="ml_content_list_item">
          <span>贷款期限：</span>
          <span>{{pageData.loanTerm}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>首付：</span>
          <span>{{pageData.vehiclePrice==null?'-':pageData.vehiclePrice+'元'}}元</span>
        </div>
        <div class="ml_content_list_item">
          <span>产品名称：</span>
          <span>{{pageData.loanProduct}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>月供：</span>
          <span>{{pageData.monthlyPaymentAmount==null?'-':pageData.monthlyPaymentAmount+'元'}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>咨询服务费:</span>
          <span>{{pageData.consultingServiceFee==null?'-':pageData.consultingServiceFee+'元'}}</span>
        </div>
      </div>
    </div>

    <div class="ml_content" style="background-image: linear-gradient(157deg,rgba(198, 255, 218,0.7) 0%,#ffffff 40px);">
      <div class="ml_content_title">增值服务</div>
      <div class="ml_content_list" style="padding-bottom:15px">
        <div class="ml_content_list_title">保险</div>
        <div class="ml_content_list_item">
          <span>保险预估：</span>
          <span>{{pageData.insuranceAmount}}元</span>
        </div>
        <div class="ml_content_list_item" style="border:none">
          <span>险种：</span>
        </div>
        <div class="ml_content_list_other" v-for="(item,index) in pageData.insuranceList" :key="index">
          <span>{{item.typeName}}</span>
          <span>{{item.typeVal}}</span>
        </div>
      </div>
      <div class="ml_content_list">
        <div class="ml_content_list_title">延保</div>
        <div class="ml_content_list_item">
          <span>延保年限：</span>
          <span>{{pageData.extendedWarrantyYear == null?'-':pageData.extendedWarrantyYear+'年'}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>延保费用：</span>
          <span>{{pageData.extendedWarrantyFee == null?'-':pageData.extendedWarrantyFee+'元'}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>其他-{{pageData.extendedWarrantyOther}}：</span>
          <span>{{pageData.extendedWarrantyOtherFee == null?'-':pageData.extendedWarrantyOtherFee+'元'}}</span>
        </div>
      </div>
      <div class="ml_content_list">
        <div class="ml_content_list_title">装潢</div>
        <div class="ml_content_list_item">
          <span style="color: #292D33;">装潢：</span>
          <span>{{pageData.decorationFee == null?'-':pageData.decorationFee}}元</span>
        </div>
      </div>
      <div class="ml_content_title" style="margin-top:15px">旧车置换</div>
      <div class="ml_content_list">
        <div class="ml_content_list_title">旧车置换</div>
        <div class="ml_content_list_other" style="border-bottom:1px solid #e9e9e9;padding-bottom:10px">
          <span>车型：</span>
          <span>{{pageData.replacementVehicleName||'-'}}</span>
        </div>
        <div class="ml_content_list_item">
          <span>旧车估值：</span>
          <span>{{pageData.usedCarValuation == null?'-':pageData.usedCarValuation+'元'}}</span>
        </div>
      </div>
    </div>

    <div class="ml_content">
      <div class="ml_other">
        <span>全款合计 <em class="ml_other_tip"  @click="alertMessage(0)">?</em> ：</span>
        <span>{{pageData.totalPayment==null?'-':pageData.totalPayment+'元'}}</span>
      </div>
      <div class="ml_other">
        <span>按揭首付合计 <em class="ml_other_tip"  @click="alertMessage(1)">?</em> ：</span>
        <span>{{pageData.mortgageDownPayment==null?'-':pageData.mortgageDownPayment+'元'}}</span>
      </div>
    </div>

    <div class="ml_content">
      <div class="ml_other_bottom">
        <span>编号：{{pageData.code==null?'-':pageData.code}}</span>
        <span class="ml_copy" v-if="pageData.code!=null" @click="alertMessage(2,pageData.code)">复制</span>
      </div>
      <div class="ml_other_bottom">
        <span>日期：{{pageData.quotationDate==null?'-':pageData.quotationDate}}</span>
      </div>
      <div style="width:100%;height:1px;background:rgba(233,233,233,1);margin:10px 0 15px 0"></div>
      <div class="ml_other_bottom">
        <span>线索来源：{{pageData.leadSourceName||'-'}}</span>
      </div>
      <div class="ml_other_bottom">
        <span>创建：{{pageData.creator||'-'}} <em style="color: #E9E9E9;">|</em>  {{pageData.storeName||'-'}}</span>
      </div>

    </div>

    <div class="ml_bottom">
      <div class="ml_bottom_title">★特此说明</div>
      <div class="ml_bottom_tip">1、此报价单有效期为    <span class="ml_bottom_day"> {{pageData.validDays}} </span>     天，自报价日开始起算。</div>
      <div class="ml_bottom_tip">2、客户可在有效期内至店订车，逾有效期后，报价单自动失效。</div>
      <div class="ml_bottom_tip">3、此报价单中部分价格为预估价格，实际价格以正式销售合同或发票为准。</div>
      <div class="ml_bottom_tip">4、此报价单中的具体贷款信息依据客户与银行签订的借款合同为准。</div>
      <div class="ml_bottom_tip">5、此报价单中保险的费用以客户与保险公司签署的保单为准；</div>
      <div class="ml_bottom_tip">6、此报价单所显示信息仅系参考信息，不构成任何要约或承诺，不作为双方之间交易依据。</div>
      <div class="ml_bottom_tip">7、本报价单最终解释权归门店所有.</div>
    </div>

    <div v-if="copyalert" class="ml_alert">{{alertText}}</div>

    <div v-if="collectStatus" class="ml_user_collect">
      <img src="../../assets/46.png" alt="">
    </div>
  </div>
</template>

<script>
import copy from 'copy-to-clipboard';
import { getVehicleQuotationPrices } from "@/utils/api"
export default {
  components: {},
  data () {
    return {
      id:0,
      collectStatus:false,
      pageData:{},
      copyalert:false,
      alertText:""
    };
  },
  computed: {},
  mounted() {
    this.id = this.$route.query.id;
    this.getVehicleQuotationPrices()
  },
  methods: {
    alertMessage(index,e){
      const that = this;
      let arr = [
        '全款合计=全款+保险预估+延保费用+其他费用+装潢+综合服务费',
        '按揭首付款合计=首付+咨询服务费+保险预估+延保费用+其他费用+装潢+综合服务费',
        '复制成功'
      ]
      that.alertText = arr[index];
      that.copyalert = true;
      setTimeout(()=>{
        that.copyalert = false;
      },1500)
      if(index == 2){
        copy(e);
      }
    },
    getVehicleQuotationPrices(){
      const that = this;
      getVehicleQuotationPrices({id:that.id,type:'h5'}).then(res=>{
        that.pageData = res.data;
      })
    }
  }
}

</script>
<style lang='scss'>
.ml_quotationprice{
  background: #f3f5f7;
  padding-bottom: 50px;
  .ml_head{
    width: 100%;
    height: 140px;
    background: url('../../assets/45.png') no-repeat;
    background-size: cover;
    background-position: center;
  }
  .ml_car_box{
    width: calc( 100% - 40px );
    background: #ffffff;
    border-radius: 16px;
    box-sizing: border-box;
    padding: 20px 15px;
    position: absolute;
    left:0;
    right:0;
    margin: 0 auto;
    top: 10px;
    .ml_userinfo{
      display: flex;
      justify-content: space-between;
      .ml_user_name{
        color: #292D33;
        font-weight: 500;
      }
      .ml_user_title{
        font-size: 10px;
        color: #292D33;
      }
      .user_user_phone{
        font-size: 14px;
        color: #292D33;
      }
    }
    .ml_carinfo{
      width: 100%;
      height: 65px;
      background: rgba($color: #292d33, $alpha: 0.05);
      border-radius: 4px;
      margin-top: 12px;
      box-sizing: border-box;
      padding:10px;
      display: flex;
      .ml_car_img{
        width: 60px;
        height: 45px;
        border-radius: 8px;
      }
      .ml_car_name{
        margin-left: 10px;
        color:#292D33;
        overflow: hidden;
        text-overflow: ellipsis;
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 2; 
      }
    }
    .ml_price_box{
      margin-top: 12px;
      span{
        &:first-child{
          font-size: 24px;
          color: #FF0000;
          font-weight: 500;
        }
        &:nth-child(2){
          font-size: 14px;
          color: #FF0000;
          font-weight: 500;
        }
        &:nth-child(3){
          font-size: 14px;
          color: #979797;
          font-weight: 400;
          margin-left: 15px;
          text-decoration: line-through;
        }
      }
    }
    .ml_price_service{
      font-size: 12px;
      color: #292D33;
      margin-top: 5px;
    }
  }
  .ml_price_purchase{
    width: 100%;
    height: 80px;
    background:  #292D33;
    padding-top: 70px;
    .ml_price_purchase_item{
      width: calc( 100% - 40px);
      margin:0 auto;
      margin-top: 12px;
      display: flex;
      justify-content: space-between;
      font-size: 14px;
      color: #FFFFFF;
    }
  }
  .ml_content_title{
    font-size: 14px;
    color: #292D33;
    font-weight: 500;
    padding-left: 8px;
    position: relative;
    &::before{
      content:"";
      position: absolute;
      top:0;
      bottom:0;
      left:0;
      margin:auto;
      width: 3px;
      height: 14px;
      background: #327fff;
    }
  }
  .ml_content{
    margin-top: 10px;
    border-radius: 16px;
    padding:17px 15px 20px;
    background: #ffffff;
    .ml_content_list{
      border: 1px solid rgba(233,233,233,1);
      border-radius: 16px;
      margin-top: 15px;
      &:first-child{
        margin-top: 17px;
      }
      .ml_content_list_title{
        line-height: 44px;
        background: rgba($color: #292d33, $alpha: 0.03);
        border-radius: 16px 16px 0px 0px;
        color: #292D33;
        font-weight: 500;
        padding:0 15px;
        box-sizing: border-box;
      }
      .ml_content_list_item{
        line-height: 44px;
        padding:0 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid rgba(233,233,233,1);
        &:last-child{
          border:none;
        }
        span{
          font-size: 14px;
          &:first-child{
            color: #979797;
          }
          &:nth-child(2){
            color: #292D33;
            font-weight: 500;
          }
        }
      }
      .ml_content_list_other{
        margin-top: 10px;
        padding:0 15px;
        box-sizing: border-box;
        display: flex;
        justify-content: space-between;
        span{
          font-size: 14px;
          color: #292D33;
          font-weight: 400;
          &:nth-child(2){
            max-width: calc( 100% - 120px );
          }
        }
      }
    }
    .ml_other{
      margin-top:15px;
      &:first-child{
        margin-top: 0;
      }
      span{
        &:first-child{
          font-size: 14px;
          color: #292D33;
        }
        &:nth-child(2){
          font-size: 14px;
          color: #292D33;
          font-weight: 600;
        }
      }
      .ml_other_tip{
        width: 14px;
        height: 14px;
        border: 1px solid rgba(41,45,51,0.3);
        border-radius: 50%;
        display: inline-block;
        color: #292D33;
        text-align: center;
        line-height: 14px;
        font-size: 10px;
      }
    }
    .ml_other_bottom{
      margin-bottom: 10px;
      font-size: 14px;
      color: #292D33;
      &:last-child{
        margin-bottom: 0;
      }
      .ml_copy{
        display: inline-block;
        width: 36px;
        height: 24px;
        background: #F3F5F7;
        border-radius: 4px;
        font-size: 10px;
        color: #292D33;
        text-align: center;
        line-height: 24px;
        transform: scale(0.8);
        margin-left: 10px;
        position: relative;
        &::before{
          content: '';
          position: absolute;
          left:-5px;
          top:-5px;
          width: 46px;
          height: 30px;
        }
      }
    }
  }
  .ml_bottom{
    color: #292D33;
    padding:17px 15px 20px;
    font-size: 14px;
    font-weight: 500;
    .ml_bottom_title{
      font-size: 14px;
      color: #292D33;
      margin-bottom: 10px;
    }
    .ml_bottom_tip{
      color: #666666;
      line-height: 24px;
    }
    .ml_bottom_day{
      display: inline-block;
      width: 80px;
      border-bottom:1px solid rgba(41,45,51,1);
      text-align: center;
    }
  }

  .ml_alert{
    max-width: 80%;
    height: fit-content;
    padding:15px;
    background: rgba($color: #292d33, $alpha: 0.8);
    border-radius: 4px;
    text-align: center;
    line-height: 30px;
    color: #ffffff;
    position: fixed;
    left:0;
    top:0;
    right:0;
    bottom:0;
    margin: auto;
  }

  .ml_user_collect{
    width:40px;
    height: 40px;
    background: #FFFFFF;
    border-radius: 50%;
    box-shadow: 0px 0px 20px 0px #cccccc;
    border: 1px solid rgba(245,247,249,1);
    position: fixed;
    right:10px;
    top:80%;
    display: flex;
    justify-content: center;
    align-items: center;
    img{
      width: 20px;
      height: 20px;
    }
  }
}
</style>